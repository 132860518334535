import type { AsyncData } from "#app/composables/asyncData";
import { EmployeeTrainingsApi, type EmployeeTrainingPagination } from "@simbelapp/order-sdk";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { usePagination } from "~~/composables/paginations/usePagination";

export const useEmployeeTrainingsApi = () => {
  async function fetchAllEmployeeTrainings(): Promise<EmployeeTrainingPagination> {
    const pagination = usePagination();
    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, EmployeeTrainingsApi);

    const response = await apiInstance.getAllEmployeeTrainings({
      ...query_string,
      sortingColumn: paginate_options.sorting_column,
    });

    return response;
  }

  async function fetchEmployeeTraining(myTrainingId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `employeeTrainings/${myTrainingId}`, {
      method: "GET",
    });
  }

  return { fetchAllEmployeeTrainings, fetchEmployeeTraining };
};
